/* Stile per la barra di navigazione */
.navbar {
  background-color: #004d99; /* Blu scuro */
  color: white;
  padding: 10px 20px;
  text-align: center;
}

.container-give-points {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-give-points {
  width: 90%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}

/* Stile per le liste e i selettori */
.select-event-give {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.participants-list-give {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 15px;
}

/* Stile per i pulsanti */
.button-give-points {
  background-color: #7209b7; /* Blu */
  color: white;
  padding: 15px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-give-points:hover {
  background-color: #005f73; /* Blu più scuro */
}

.select-event-give:focus {
  border-color: #7209b7; /* Cambia il colore del bordo quando è in focus */
  outline: none; /* Rimuove l'outline predefinito per mantenere il design pulito */
}

/* Stile base per le card */
.card-container-give {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Distanza tra le card */
}

/* Stile per ogni card */
.card-give {
  border: 1px solid #ccc; /* Bordo sottile per definizione */
  border-radius: 10px; /* Bordi arrotondati per un aspetto moderno */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombra leggera per profondità */
  padding: 20px; /* Spazio interno per contenuto */
  margin-bottom: 20px; /* Distanza tra le card quando sono in colonna */
  background-color: white; /* Sfondo bianco per leggibilità */
  color: #635f5f; /* Colore del testo per un buon contrasto */
  font-size: 16px; /* Dimensione del testo per leggibilità */
}

/* Stile per i link all'interno delle card */
.card-link-give {
  color: #0066cc; /* Colore dei link */
  text-decoration: none; /* Rimuove il sottolineato dai link */
}

.card-link-give:hover {
  text-decoration: underline; /* Aggiunge sottolineato al passaggio del mouse per indicare cliccabilità */
}
