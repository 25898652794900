/* NavBar.css */
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff; /* Colore di sfondo */
  color: rgb(56, 56, 56);
  display: flex;
  justify-content: center; /* Centra gli elementi orizzontalmente */
  align-items: center; /* Centra gli elementi verticalmente */
  padding: 10px 0;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.119);
}

.bottom-nav ul {
  list-style: none;
  display: flex; /* Disponi gli elementi della lista orizzontalmente */
  padding: 0;
  margin: 0;
}

.bottom-nav ul li {
  margin: 0 20px; /* Distanza tra gli elementi della lista */
}

.bottom-nav ul li a {
  color: rgb(45, 45, 45); /* Colore del testo dei link */
  text-decoration: none; /* Rimuove il sottolineato dai link */
  font-size: 14px; /* Dimensione del testo */
  transition: color 0.3s; /* Transizione fluida per il cambio colore */
}

.bottom-nav ul li a:hover {
  color: #aaa; /* Cambia il colore al passaggio del mouse */
}

.nav-item {
  display: flex;
  flex-direction: column; /* Organizza l'icona e il testo in colonna */
  align-items: center; /* Centra gli elementi orizzontalmente */
  justify-content: center; /* Centra gli elementi verticalmente */
  text-align: center; /* Assicura che il testo sia centrato sotto l'icona */
}

.nav-item span {
  margin-top: 5px; /* Aggiunge spazio tra l'icona e il testo */
}

.icon-nav {
  font-size: 24px; /* Dimensione dell'icona */
}

.nav-item.active {
  color: #7209b7; /* Cambia questo colore con quello che preferisci */
}
