/* Stilizza il contenitore principale */
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
}

/* Stilizza il form di login */
.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 45vh;
  height: 60vh;
}

/* Stilizza gli input */
.register-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box; /* Assicura che padding e bordo siano inclusi nella larghezza */
}

/* Stilizza il bottone di login */
.register-button {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #7209b7;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.register-select {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
}

.register-button:hover {
  background-color: #7209b7;
}

.account {
  color: #7209b7;
}

.logo {
  width: 200px;
  padding-bottom: 10px;
}
