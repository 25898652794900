/* create-event.css */

.container-create-event {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
  margin-bottom: 100px;
}

.form-group-create-event {
  margin-bottom: 15px;
  width: 100%;
  max-width: 320px;
}

.form-group-create-event input,
.form-group-create-event select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
}

.form-group-create-event label {
  font-weight: bold;
}

.button-submit-event {
  background-color: #7209b7;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;
}

.textarea-style {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 340px;
}

/* Se vuoi che la textarea abbia uno stile leggermente diverso, puoi sovrascrivere alcune proprietà nella classe .textarea-style */
.textarea-style {
  height: 150px; /* Altezza specifica per la textarea */
  resize: vertical; /* Permette all'utente di ridimensionare la textarea solo verticalmente */
}

.button-submit-event:hover {
  background-color: transparent;
  color: #7209b7;
  border: 1px solid #7209b7;
}

.button-submit-event:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.create-event-header {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Questo centra verticalmente il logo e l'h2 */
  padding-left: 0px;
  margin-top: 20px;
  padding-right: 2rem;
  margin-bottom: 20px;
}

.create-event-title {
  margin-top: 5px;
}
