.homediv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

a.button-login-home {
  display: block;
  padding: 10px 20px;
  margin: 10px;
  background-color: #7209b7;
  border: 1px solid #7209b7;
  border-radius: 5px;
  text-align: center;
  width: 200px; /* o una larghezza specifica che preferisci */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: #fff;
}

a.button-login-home:hover {
  border: 1px solid #7209b7;
  background-color: #fff;
  color: #7209b7;
}

.button-register-home {
  display: block;
  padding: 10px 20px;
  margin: 10px;
  border: 1px solid #7209b7;
  background-color: #fff;
  color: #7209b7;
  border-radius: 5px;
  text-align: center;
  width: 200px; /* o una larghezza specifica che preferisci */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.button-register-home:hover {
  border: 1px solid #7209b7;
  background-color: #7209b7;
  color: #fff;
}

.logo {
  width: 200px;
  padding-bottom: 10px;
}
