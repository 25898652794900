/* ranking.css */

/* Stile per il contenitore della classifica */

ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ranking-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

/* Stile per ogni elemento della classifica */
.ranking-item {
  padding: 10px;
  margin: 5px 0;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
}

/* Stile per evidenziare l'utente corrente */
.ranking-item.current-user {
  background-color: #e0ffe0;
}

/* Stile per il nome dell'utente e i punti nella classifica */
.ranking-name,
.ranking-points {
  font-size: 16px;
  font-weight: bold;
}

/* Stile aggiuntivo per i punti per distinguerli */
.ranking-points {
  color: #7209b7;
}
