/* src/styles/profilo.css */
.profile-container-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-container-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name-profile {
  font-size: 1.5rem;
  color: #7209b7;
}

.mail-profile {
  margin-top: -20px;
}

.role-profile {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #7209b7;
}

.input-file {
  display: none;
}

.label-file {
  background-color: #6200ea;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.label-file:hover {
  background-color: #3700b3;
}

.button-upload {
  background-color: #03dac5;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.button-upload:hover {
  background-color: #018786;
}

.profile-picture-container {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-size: cover;
  background-position: center;
}

.profile-picture-icon {
  font-size: 2em;
  color: #ffffffa6;
}

.profile-details-container {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  padding: 20px;
  gap: 10px;
}

.card-profile {
  background-color: #7209b7;
  border: 1px solid #ddd;
  color: #fff;
  padding: 20px;
  width: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.card-profile h3 {
  margin: 0;
  font-weight: normal;
}

.card-profile p {
  font-size: 2rem;
  margin: 0;
  font-weight: bold;
}

.logout-button-profile {
  background-color: #7209b7;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.profile-container-logout-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
