body {
  background-color: #fff;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px; /* Distanza tra le card */
  justify-content: center; /* Centra le card nel contenitore se non occupano tutta la larghezza */
  margin-bottom: 100px;
}

.card-link {
  text-decoration: none; /* Rimuove il sottolineamento dei link */
  color: inherit; /* Mantiene il colore del testo ereditato dal parent */
}

.card {
  background-color: #fff;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  flex: 1; /* Aggiunge flessibilità alle card */
  width: 90vw; /* Imposta un min-width per le card */
}
.card-event-info {
  padding: 0px 20px;
}

.card:hover {
  transform: scale(1.03);
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Questo centra verticalmente il logo e l'h2 */
  padding-left: 0px;
  margin-top: 20px;
  padding-right: 2rem;
}

.logo {
  width: 150px; /* Imposta la larghezza del logo */
}

.dashboard-title {
  margin-top: -12px;
}

.welcome {
  text-align: right;
  padding-right: 2rem;
  margin-top: -37px;
  margin-bottom: 35px;
}

.eventi-title {
  margin-left: 2rem;
}

.role {
  text-transform: uppercase;
}

.event-title {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #7209b7;
}

.event-location {
  font-size: 1rem;
  font-weight: normal;
  color: #818181;
  margin-bottom: 5px;
}

.Date-fee {
  color: #818181;
  display: flex;
  justify-content: space-between;
  margin-bottom: -15px;
}

.event-fee {
  font-size: 1rem;
  font-weight: normal;
}

.event-price {
  font-weight: bold;
  color: #7209b7;
}

.disponibili {
  font-weight: normal;
}
