/* event-details.css */

.event-details-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 120px;
  padding: 20px;
  border-radius: 20px 20px 0 0;
  background-color: #fff;
  margin-top: -30px;
  z-index: 10;
}

.event-info {
  font-size: 16px;
  color: #000;
  line-height: 1.5;
  margin-bottom: 10px;
}

.event-title-details {
  margin-top: 5px;
  margin-bottom: -10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #7209b7;
}

.date-hours-details-event {
  display: flex;
  gap: 10px;

  margin-bottom: 10px;
}

.event-details-location,
.event-details-date {
  font-weight: normal;
}

.sub-button {
  background-color: #7209b7;
  color: white;
  padding: 18px 60px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.sub-button:hover {
  background-color: #7209b7;
}

.sub-sez-container {
  padding: 40px;
}

.sub-sez {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(173, 173, 173, 0.316);
  margin: 10px;
}

.iconback-event-details {
  font-size: 30px;
  color: #fff;
  background-color: #7109b7;
  border-radius: 50%;
  padding: 10px;
}
