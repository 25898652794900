/* Stile per il contenitore principale della lista */
.participant-list-container {
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  margin-top: -25px;
}

/* Stile per l'elenco dei partecipanti */
.participant-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.participant-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Stile per i dettagli del partecipante */
.participant-details {
  display: flex;
  align-items: center;
}

.participant-name {
  margin-right: 15px;
  font-weight: bold;
}

/* Stile per i pulsanti */
.button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
}

.add-participant-button {
  background-color: #7209b7; /* Verde */
  padding: 10px 20px;
  margin-left: 10px;
}

.delete-participant-button {
  background-color: #f44336; /* Rosso */
}

/* Stile per il campo di input */
.input-field {
  width: 60%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Stile per il messaggio di errore */
.error-message {
  color: #f44336; /* Rosso */
  font-size: 0.9rem;
}

.iconback-event-list {
  font-size: 30px;
  color: #fff;
  background-color: #7109b7;
  border-radius: 50%;
  padding: 10px;
}

.title-list {
  font-size: 1.5rem;
  font-weight: bold;
  color: #7209b7;
}

.header-list {
  text-align: right;
}

.partecipanti-list-header {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  margin-top: 20px;
}

.search-input-list {
  width: 90%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}
